// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_28o3df9BjcRX6qs7ta", // ec
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/4gw7tlcbI8Rw1osdRx", // ec

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Eye Color AI 👁",
    HOME_PAGE_TITLE: "Home - Eye Color AI 👁",
    DASHBOARD_PAGE_TITLE: "Home - Eye Color AI 👁",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Eye Color AI 👁",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to edit eye color in images, choose an image and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "eye_color_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/eye-color-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;